export const columns = [
  {
    name: "City / Town",
    label: "City / Town",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "State",
    label: "State",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const rows = [
  ["Bethleham", "PA"],
  ["Macunge", "PA"],
  ["Chalfont", "PA"],
  ["Furlong", "PA"],
  ["Jamison", "PA"],
  ["New Hope", "PA"],
  ["Newtown/Wrightstown", "PA"],
  ["Perkasie", "PA"],
  ["Quakertown", "PA"],
  ["Richboro", "PA"],
  ["Churchville", "PA"],
  ["Holland", "PA"],
  ["N. Hampton", "PA"],
  ["Warrington", "PA"],
  ["Warwick", "PA"],
  ["Ivyland", "PA"],
  ["Warminster", "PA"],
  ["Washington Crossing", "PA"],
  ["Abington", "PA"],
  ["Ambler", "PA"],
  ["Maple Glen", "PA"],
  ["Ardmore", "PA"],
  ["Bala Cynwynd", "PA"],
  ["Meadowbrook", "PA"],
  ["Huntingdon Valley", "PA"],
  ["Broomall", "PA"],
  ["Bryn Mawr", "PA"],
  ["Rosemont", "PA"],
  ["Aston", "PA"],
  ["Brookhaven", "PA"],
  ["Bensalem", "PA"],
  ["Dresher", "PA"],
  ["Drexel Hill", "PA"],
  ["Elkins Park", "PA"],
  ["Fairless Hills", "PA"],
  ["Flourtown", "PA"],
  ["Folsom", "PA"],
  ["Ft. Washington", "PA"],
  ["Gladwyne", "PA"],
  ["Wyndmoor", "PA"],
  ["Glenside", "PA"],
  ["Hatboro", "PA"],
  ["Haverford", "PA"],
  ["Holmes", "PA"],
  ["Horsham", "PA"],
  ["Rydal", "PA"],
  ["Langhorn", "PA"],
  ["Trevose", "PA"],
  ["Livittown", "PA"],
  ["Media", "PA"],
  ["Springfield", "PA"],
  ["Merion Station", "PA"],
  ["Yardley", "PA"],
  ["Morrisville", "PA"],
  ["Narberth", "PA"],
  ["Penn Valley", "PA"],
  ["Newtown Square", "PA"],
  ["Ridley Park", "PA"],
  ["Sharon Hill", "PA"],
  ["Swarthmore", "PA"],
  ["Havertown", "PA"],
  ["Villanova", "PA"],
  ["Rose Valley", "PA"],
  ["Wallingford", "PA"],
  ["St. Davids", "PA"],
  ["Wayne", "PA"],
  ["Radnor", "PA"],
  ["Strafford", "PA"],
  ["Willow Grove", "PA"],
  ["Wyncote", "PA"],
  ["Wynnewood", "PA"],
  ["Paoli", "PA"],
  ["Avondale", "PA"],
  ["Berwyne", "PA"],
  ["Chaddsford", "PA"],
  ["Coatsville", "PA"],
  ["Cochranville", "PA"],
  ["Devon", "PA"],
  ["Downingtown", "PA"],
  ["Lionville", "PA"],
  ["Exton", "PA"],
  ["Glen Mills", "PA"],
  ["Glenmoore", "PA"],
  ["Honeybrook", "PA"],
  ["Kennett Square", "PA"],
  ["Landenberg", "PA"],
  ["Lincoln University", "PA"],
  ["Malvern", "PA"],
  ["Oxford", "PA"],
  ["Thordale", "PA"],
  ["Unionville", "PA"],
  ["West Chester", "PA"],
  ["West Grove", "PA"],
  ["Norriton", "PA"],
  ["Audobon", "PA"],
  ["Jeffersonville", "PA"],
  ["Valley Forge", "PA"],
  ["King of Prussia", "PA"],
  ["Birchrunville", "PA"],
  ["Blue Bell", "PA"],
  ["Chester Springs", "PA"],
  ["Collegeville", "PA"],
  ["Conshohocken", "PA"],
  ["West Conshohocken", "PA"],
  ["Harleysville", "PA"],
  ["Lafayette Hill", "PA"],
  ["Lansdale", "PA"],
  ["N. Wales", "PA"],
  ["Phoenixville", "PA"],
  ["Plymouth Meeting", "PA"],
  ["Pottstown", "PA"],
  ["Royersville", "PA"],
  ["Schwenksville", "PA"],
  ["Skippack", "PA"],
  ["Spring City", "PA"],
  ["Worcester", "PA"],
  ["Douglasville", "PA"],
  ["Gilbertsville", "PA"],
  ["Hamburg", "PA"],
  ["Morgantown", "PA"],
  ["Reading", "PA"],
  ["Sinking Springs", "PA"],
  ["West Lawn", "PA"],
  ["Doylestown", "PA"],
  ["Garnett Valley", "PA"],
  ["Boothwyn", "PA"],
  ["Bear", "DE"],
  ["Newark", "DE"],
  ["Claymont", "DE"],
  ["Elsmere", "DE"],
  ["Hockessin", "DE"],
  ["Middletown", "DE"],
  ["New Castle", "DE"],
  ["Townsend", "DE"],
  ["Wilmington", "DE"],
  ["Greenville", "DE"],
  ["Centerville", "DE"],
  ["Montchanin", "DE"],
  ["Wyoming", "DE"],
  ["Federica", "DE"],
  ["Georgetown", "DE"],
  ["Rehobeth", "DE"],
  ["Smyrna", "DE"],
  ["Aberdeen", "MD"],
  ["Havre de Grace", "MD"],
  ["Rock Hall", "MD"],
  ["North East", "MD"],
  ["Port Deposit", "MD"],
  ["Rising Sun", "MD"],
  ["Warwick", "MD"],
  ["Elkton", "MD"],
  ["Salem", "NJ"],
  ["Bridgeton", "NJ"],
  ["Millville", "NJ"],
  ["Vineland", "NJ"],
  ["Glassboro", "NJ"],
  ["Hammonton", "NJ"],
  ["Woodbury", "NJ"],
  ["Swedesboro", "NJ"],
];
